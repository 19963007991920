import React, { useState, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Backdrop
} from '@mui/material';
import Topbar from '../../components/topbar/Topbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HOVInput from '../../components/hovInput/HOVInput';
import AutoCompleteWithChips from '../../components/autoCompleteWithChips/AutoCompleteWithChips';
import HOVButton from '../../components/hovButton/HOVButton';
import DrawerContext from '../../context/DrawerContext';
import Loader from '../../components/loader/Loader';
import useApiCalls from '../../hooks/useApiCalls';
import { BU_USER, SUPER_USER } from '../../roles';
import { GlobalContext } from '../../App';

const EditBusinessUnit = (props) => {
  const controlsStyle = { maxWidth: '22vw' };
  const [backdropOpen, setBackdrop] = useState(false);
  const [attributeData, setAttributeData] = useState(null);
  const drawerState = useContext(DrawerContext).drawerState;
  const [update, setUpdate] = useState(false);
  const {
    getFormData,
    updateFormData,
    useMessage,
    useSeverity,
    useOpen,
    useAttributeData,
    useHttpStatus,
    useError
  } = useApiCalls();
  const {
    setOpen,
    setMessage,
    setSeverity,
    handleEditBusinessUnitClose,
    edit
  } = props;

  const { state } = useContext(GlobalContext);
  // Initial call for form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      getFormData(`bizunit/${props.id}`);
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Setting form data
  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      setAttributeData(useAttributeData);
    }

    return () => {
      suscribe = false;
    }
  }, [useAttributeData]);


  // For showing any error while fetching form data
  useEffect(() => {
    if (useError) {
      setMessage(useMessage);
      setSeverity(useSeverity);
      setOpen(useOpen);

      setTimeout(() => {
        handleEditBusinessUnitClose();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useError, useMessage, useSeverity, useOpen]);

  // Checking for update success/failure
  useEffect(() => {
    if (update) {
      setMessage(useMessage);
      setSeverity(useSeverity);
      setOpen(useOpen);

      if (useHttpStatus === 201) {
        setTimeout(() => {
          setBackdrop(false);
          handleEditBusinessUnitClose();
        }, 2000);
      }
      else {
        setBackdrop(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMessage, useHttpStatus, useOpen]);


  // Setting props value for topbar component
  const values = {
    header: "Edit Business Unit",
    edit: edit,
    handleClose: handleEditBusinessUnitClose
  };
  const isReadOnlyUser = (state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? false : true
  return (
    <div className="page-table-container">
      <Topbar value={values} />
      <div
        className="form-container"
        style={{ height: 'fit-content', width: '90%' }}
      >
        {
          attributeData ?
            (
              <Formik
                initialValues={{
                  businessUnit: attributeData?.biz_unit,
                  businessUnitLongName: attributeData?.biz_unit_long_name,
                  accountId: attributeData?.account_ids?.map(id => ({
                    label: id,
                    value: id
                  }))
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                  setUpdate(false);
                  setBackdrop(true);
                  try {
                    const keys = Object.keys(values);
                    const keysLength = keys.length;

                    let formatedValues = {
                      businessUnit: '',
                      businessUnitLongName: '',
                      accountId: '',
                    };

                    // filtering only values and removing labels
                    const filteredValues = keys.map((key) => {
                      if (values[key] instanceof Array) {
                        return values[key].map(
                          (singleArrayValue) => singleArrayValue.value
                        );
                      }
                      return values[key];
                    });

                    // mapping filtered values to formatedValues
                    for (let index = 0; index < keysLength; ++index) {
                      let key = keys[index];
                      formatedValues[key] = filteredValues[index];
                    }

                    // Account Id validation
                    const accountIdExp = new RegExp(/^[0-9]+$/)

                    for (let id = 0; id < formatedValues["accountId"].length; id++) {
                      if (formatedValues["accountId"][id].length < 12 || !accountIdExp.test(formatedValues["accountId"][id])) {
                        setOpen(true);
                        setMessage(`Please enter valid account id`);
                        setSeverity("error");
                        setBackdrop(false);
                        return;
                      }
                    }

                    formatedValues['id'] = attributeData?.id;

                    updateFormData('bizunit', formatedValues, 'SET_BUSINESSUNIT_DATA', 'bizunit');
                    setUpdate(true);
                  }
                  catch (error) {
                    setOpen(true);
                    setMessage(error.message);
                    setSeverity("error");
                    setBackdrop(false);
                  }
                }}
              >
                {(formikProps) => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Business Unit"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.businessUnit}
                            name="businessUnit"
                            disable
                          />
                          {formikProps.errors.businessUnit &&
                            formikProps.touched.businessUnit ? (
                            <div className="text-danger">
                              {formikProps.errors.businessUnit}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <HOVInput
                            label="Business Unit Long Name"
                            required
                            onChangeProp={formikProps.handleChange}
                            onBlurProp={formikProps.handleBlur}
                            valueProp={formikProps.values.businessUnitLongName}
                            name="businessUnitLongName"
                            disable={isReadOnlyUser}
                          />
                          {formikProps.errors.businessUnitLongName &&
                            formikProps.touched.businessUnitLongName ? (
                            <div className="text-danger">
                              {formikProps.errors.businessUnitLongName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 mb-4">
                          <AutoCompleteWithChips
                            label="Account ID(s):"
                            value={formikProps.values.accountId}
                            onChange={(e) => formikProps.setFieldValue(`accountId`, e)}
                            name="accountId"
                            form={formikProps}
                            xstyle={controlsStyle}
                            option="account"
                            required
                            disable={isReadOnlyUser}
                          />
                          {formikProps.errors.accountId &&
                            formikProps.touched.accountId ? (
                            <div className="text-danger">
                              {formikProps.errors.accountId}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex align-items-center h-100 mt-3 mt-lg-0 justify-content-end button-layout">
                        {(state?.user?.userRole === SUPER_USER || state?.user?.userRole === BU_USER) ? (
                          <div>
                          <HOVButton
                            value="Update"
                            color="#fff"
                            backgroundColor="#008542"
                            border="none"
                            className="px-5 py-2"
                            buttonWidth={140}
                            custom
                            type="submit"
                            onClick={formikProps.handleSubmit}
                          />
                        </div>
                        ) : null}
                        
                        <span className="mx-3"></span>
                        <div>
                          <HOVButton
                            value="Cancel"
                            color="#fff"
                            backgroundColor="#FF4E50"
                            border="none"
                            className="px-5 py-2"
                            buttonWidth={140}
                            custom
                            onClick={() => {
                              handleEditBusinessUnitClose()
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )
            :
            (
              <div style={{ height: "20vh" }}>
                <Loader />
              </div>
            )
        }
        <Backdrop
          open={backdropOpen}
          style={{ zIndex: 100 }}
        >
          <CircularProgress
            style={drawerState ? {
              marginLeft: 200
            } : { margin: "0 auto" }}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default EditBusinessUnit;

const ValidationSchema = Yup.object().shape({
  businessUnit: Yup.string().required('Please provide a business unit.'),
  businessUnitLongName: Yup.string().required(
    'Please provide a business unit long name.'
  ),
  accountId: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, 'Please enter account Id'),
});